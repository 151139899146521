import {cloneDeep, isEqual, isEmpty} from "lodash";
import {useGlobalStore} from "@/stores/useGlobalStore";
import {instance} from "@/configs/request.config";
import {TOKEN} from "@/constants";
import router from "@/router";
import {Utility} from "@/utility";

/**
 *
 * @param payloadRequest the request name which is mapped to its corresponding table.
 * @param payloadType the request type which indicates the type of action that the request will initiate.
 *
 * @returns {{addPayload: (function(*, *): addPayload), deletePayload: (function(*, *): *), setPayload: ((function(*, *, null=): (this))|*), generatePayload: ((function(): ({}))|*), execute: ((function({}=, string=): Promise<*|undefined>)|*), resetPayload: (function(): resetPayload)}}
 */
export default function(payloadRequest, payloadType) {
    //This store will hold all list related data.
    const globalStore = useGlobalStore();

    if (!payloadRequest || !payloadType) {
        throw new Error("Cannot set as empty or null");
    }


    let request = payloadRequest;
    let type = payloadType;

    //This object will hold additional data which will be used in the backend.
    //As of now the most common data are 'values' and 'conditions'.
    //However, data to be provided depends on the corresponding request and its type.
    //So, confirm in the database if the data provided by the others object is indicated in the database.
    /**
     *
     * Sample others object would be
     * {
     *     values: {
     *         firstName: "something",
     *         lastName: "something"
     *     },
     *     conditions: {
     *         id: 1
     *     }
     * }
     */
    let others = {};

    //The final generated payload would be stored here including the request and its type
    let finalPayload = {};

    function resetPayload() {
        request = "";
        type = "";
        others = {};

        return this;
    }

    function setPayload(payloadRequest, payloadType, payloadOthers = null) {
        request = payloadRequest;
        type = payloadType;

        if (payloadOthers === null) {
            others = {};
            return this;
        }

        Object.keys(payloadOthers).forEach(key => {
            others[key] = payloadOthers[key];
        });

        return this;
    }

    function addPayload(key, value) {
        others[key] = value;
        return this;
    }

    function deletePayload(obj, keys) {
        keys.forEach(key => delete obj[key]);
        return obj;
    }


    function isEqualPayload() {
        if (finalPayload.request !== request) return false;
        if (finalPayload.type !== type) return false;
        let clonedPayload = cloneDeep(finalPayload);
        delete clonedPayload.request;
        delete clonedPayload.type;
        return isEqual(clonedPayload, others);
    }

    function generatePayload() {
        if (!isEmpty(finalPayload) && isEqualPayload()) return finalPayload;

        const payload = {};
        payload.request = request;
        payload.type = type;
        let copyObject = cloneDeep(others);
        Object.keys(copyObject).forEach(key => {
            payload[key] = copyObject[key];
        });

        finalPayload = payload;

        return payload;
    }

  async function api_request(payload, config = {}) {
    let preConfig ={
      headers: {
        "Content-Type": "application/json"
      }
    };

    if(globalStore.request_header === 'multipart'){
        preConfig = {headers: {"Content-Type": "multipart/form-data"}}
    }

    preConfig.headers = {...preConfig.headers, ...config.headers};

    try {
      return await instance.post("request", payload, preConfig);
    } catch(error) {
      if (error.response && error.response.data.msg === "Unauthorized") {
        localStorage.removeItem(TOKEN);
        localStorage.removeItem('authUser');
        localStorage.removeItem('expirationLimit');
        await router.push({name: 'login'});
      } else {
        return error.response;
      }
    }
  }

    //the config variable holds the configurations related to the http request.
    //in order to modify the Content-type of the http request.
    async function execute (config = {}, state = "")  {
        const response = await api_request(generatePayload(), {...config});
        if(response){
            if(state){
                let patchObject = {};
                patchObject[state] = response.data.data.map(datum => Utility.camelCaseProperties(datum));
                globalStore.$patch(patchObject);
            }
            return response;
        }
    }

    return {
        resetPayload,
        setPayload,
        addPayload,
        deletePayload,
        generatePayload,
        execute
    }
}

