//import './css/custom.css'
import './css/main.css'
import "@vueform/multiselect/themes/default.css";
import 'bootstrap';
import $ from 'jquery';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import icons from '@/configs/icons.config'
import components from '@/configs/components.config'
import 'sweetalert2/dist/sweetalert2.min.css'
// fonts
import '@fontsource-variable/inter';
import '@fontsource-variable/montserrat';

import { createApp } from 'vue'
import { createPinia } from "pinia";
import App from './App.vue'
import router from './router'

const app = createApp(App)
icons();
components(app);
app.component('font-awesome-icon', FontAwesomeIcon)
app.use(router)
app.use(createPinia())
window.$ = $
app.mount('#app')
